import * as React from "react"
import styled from 'styled-components'
import Seo from "../components/SEO";
import SearchField from "../components/Search/SearchField";

const Style = styled.div`
  .doc {
    border: solid 4px #d2d2d2;
    border-radius: 4px;
    padding: 12px 24px;
    margin-bottom: 48px;
    
    span {
      color: #006cae;
    }
    
    .section {
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 1.5rem;
    }
  }
  
  .search p {
    margin-top: 70px;
    margin-bottom: 48px;
  }
`

// markup
const NotFoundPage = () => {
  return (
    <Style>
      <Seo title={'Not Found'} />
      <h1>notFound</h1>
      <div className={'doc'}>
        <code>public static <span>ModelAndView</span> not found()</code>
        <div className={'section'}>Returns:</div>
        <div>Provides a model and view that represents a 404 / not found</div>
        <div className={'section'}>See also:</div>
        <div><u>HttpError</u></div>
      </div>
      <div className={'search'}>
        <p>
          We prepared this awesome input field for you, so you can try searching for what you're looking for.
          {" "}
          <span role={'img'} aria-label={'Search emoji'}>🔎</span>
        </p>
        <SearchField type={'full'} />
      </div>
    </Style>
  )
}

export default NotFoundPage
